import { CONSTRAINED } from '../../store/actionTypes';
export const initialState = {
    "symbol_table": [
        {
            "input": true,
            "name": "PRESSURE",
            "value": 500,
            "units": "LB/SQ-IN",
            "lmin": 0,
            "lmax": CONSTRAINED,
            "cmin": 0,
            "cmax": 1500,
            "validmin": -Number.MAX_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 1.0,
            "tooltip": "<Table><tr><td>Gas PRESSURE applied to piston AREA</td></tr><tr><td><Image fluid src=\"/designtypes/Piston-Cylinder/tooltips/PRESSURE.png\"/></td></tr></Table>",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": true,
            "name": "RADIUS",
            "value": 0.4,
            "units": "INCH",
            "lmin": CONSTRAINED,
            "lmax": CONSTRAINED,
            "cmin": 0.04,
            "cmax": 0.5,
            "validmin": Number.MIN_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 0.01,
            "tooltip": "<Table><tr><td>Piston dimension; creates AREA where PRESSURE is applied</td></tr><tr><td><Image fluid src=\"/designtypes/Piston-Cylinder/tooltips/RADIUS.png\"/></td></tr></Table>",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": true,
            "name": "THICKNESS",
            "value": 0.04,
            "units": "INCH",
            "lmin": CONSTRAINED,
            "lmax": CONSTRAINED,
            "cmin": 0.002,
            "cmax": 0.05,
            "validmin": Number.MIN_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 0.001,
            "tooltip": "<Table><tr><td>Dimension of cylinder wall providing containment for PRESSURE</td></tr><tr><td><Image fluid src=\"/designtypes/Piston-Cylinder/tooltips/THICKNESS.png\"/></td></tr></Table>",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": false,
            "name": "FORCE",
            "value": 0,
            "units": "LBS.",
            "lmin": CONSTRAINED,
            "lmax": 0,
            "cmin": 1000,
            "cmax": 10000,
            "validmin": -Number.MAX_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 1,
            "tooltip": "<Table><tr><td>Push generated by PRESSURE acting on piston AREA</td></tr><tr><td><Image fluid src=\"/designtypes/Piston-Cylinder/tooltips/FORCE.png\"/></td></tr></Table>",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": false,
            "name": "AREA",
            "value": 0,
            "units": "SQ.-IN.",
            "lmin": 0,
            "lmax": 0,
            "cmin": 0,
            "cmax": 10,
            "validmin": 0.0,
            "validmax": Number.MAX_VALUE,
            "sdlim": 0.1,
            "tooltip": "<Table><tr><td>Effective amount of piston surface exposed to PRESSURE</td></tr><tr><td><Image fluid src=\"/designtypes/Piston-Cylinder/tooltips/AREA.png\"/></td></tr></Table>",
            "type": "equationset",
            "hidden": false
        },
        {
            "input": false,
            "name": "STRESS",
            "value": 0,
            "units": "PSI",
            "lmin": 0,
            "lmax": CONSTRAINED,
            "cmin": 0,
            "cmax": 3000,
            "validmin": -Number.MAX_VALUE,
            "validmax": Number.MAX_VALUE,
            "sdlim": 10,
            "tooltip": "<Table><tr><td>Force per unit area within cylinder wall; created by PRESSURE</td></tr><tr><td><Image fluid src=\"/designtypes/Piston-Cylinder/tooltips/STRESS.png\"/></td></tr></Table>",
            "type": "equationset",
            "hidden": false
        }
    ],
    "labels": [
        {
            "name": "COMMENT",
            "value": "PCYL default start point - US units ..."
        }
    ],
    "type": "Piston-Cylinder",
    "version": "7",
    "result": {
        "objective_value": 0,
        "termination_condition": ""
    },
    "jsontype": "ODOP",
    "units": "US"
};
